import {useEffect, useRef, useCallback} from 'react';
import useStateWithCallback, {CallbackType} from './useStateWithCallback';
import {addSocketListener, removeSocketListener, sendSocketData} from "../socket";
import ACTIONS from "../socket/actions";

export default function useWebRTC(roomID: string) {
    const [clients, updateClients] = useStateWithCallback<number[]>([]);

    const addNewClient = useCallback((newClient: number, cb: CallbackType) => {
        updateClients(list => {
            if (!list.includes(newClient)) {
                return [...list, newClient]
            }
            return list;
        }, cb);
    }, [clients, updateClients]);

    const peerConnections = useRef<Map<number, RTCPeerConnection>>(new Map<number, RTCPeerConnection>());
    const localMediaStream = useRef<MediaStream | null>(null);
    const peerMediaElements = useRef<Map<number, HTMLVideoElement>>(new Map<number, HTMLVideoElement>());

    const initPeerConnection = (peerID: number) => {
        if (peerID in peerConnections.current) {
            return console.warn(`Already connected to peer ${peerID}`);
        }

        const connection = new RTCPeerConnection({
            iceServers: [
                // {
                //   urls: 'stun:[STUN_IP]:[PORT]',
                //   'credentials': '[YOR CREDENTIALS]',
                //   'username': '[USERNAME]'
                // },
                {
                    urls: "stun:stun.l.google.com:19302",
                },
            ],
        });

        peerConnections.current.set(peerID, connection);

        connection.onicecandidate = event => {
            if (event.candidate) {
                sendSocketData(roomID, ACTIONS.ICE, peerID, event.candidate);
            }
        }

        let tracksNumber = 0;
        connection.ontrack = ({streams: [remoteStream]}) => {
            tracksNumber++

            if (tracksNumber === 2) { // video & audio tracks received
                tracksNumber = 0;
                addNewClient(peerID, () => {
                    if (peerMediaElements.current.has(peerID)) {
                        const mediaElement = peerMediaElements.current.get(peerID);
                        if(mediaElement) {
                            mediaElement.srcObject = remoteStream;
                        }
                    } else {
                        // FIX LONG RENDER IN CASE OF MANY CLIENTS
                        let settled = false;
                        const interval = setInterval(() => {
                            if (peerMediaElements.current.has(peerID)) {
                                const mediaElement = peerMediaElements.current.get(peerID);
                                if(mediaElement) {
                                    mediaElement.srcObject = remoteStream;
                                }
                                settled = true;
                            }

                            if (settled) {
                                clearInterval(interval);
                            }
                        }, 1000);
                    }
                });
            }
        }

        localMediaStream.current?.getTracks().forEach(track => {
            if(localMediaStream.current && peerConnections.current.has(peerID)) {
                peerConnections.current.get(peerID)?.addTrack(track, localMediaStream.current);
            }
        });
    }

    useEffect(() => {
        async function handleJoin(peerID: number, data: any) {
            console.log('handle join');
            initPeerConnection(peerID);

            // create offer
            const offer = await peerConnections.current.get(peerID)?.createOffer();
            await peerConnections.current.get(peerID)?.setLocalDescription(offer);

            sendSocketData(roomID, ACTIONS.OFFER, peerID, offer);
        }

        addSocketListener(roomID, ACTIONS.JOIN, handleJoin);
        return () => {
            removeSocketListener(roomID, ACTIONS.JOIN, handleJoin);
        }
    }, []);

    useEffect(() => {
        async function handleOffer(peerID: number, data: any) {
            console.log('handle offer');
            initPeerConnection(peerID);

            await peerConnections.current.get(peerID)?.setRemoteDescription(
                new RTCSessionDescription(data)
            );

            const answer = await peerConnections.current.get(peerID)?.createAnswer();
            await peerConnections.current.get(peerID)?.setLocalDescription(answer);

            sendSocketData(roomID, ACTIONS.ANSWER, peerID, answer);
        }

        addSocketListener(roomID, ACTIONS.OFFER, handleOffer);
        return () => {
            removeSocketListener(roomID, ACTIONS.OFFER, handleOffer);
        }
    }, []);

    useEffect(() => {
        async function handleAnswer(peerID: number, data: any) {
            console.log('handle answer');
            await peerConnections.current.get(peerID)?.setRemoteDescription(
                new RTCSessionDescription(data)
            );
        }

        addSocketListener(roomID, ACTIONS.ANSWER, handleAnswer);
        return () => {
            removeSocketListener(roomID, ACTIONS.ANSWER, handleAnswer);
        }
    }, []);

    useEffect(() => {
        async function handleIce(peerID: number, data: any) {
            console.log('handle ice candidate');
            peerConnections.current.get(peerID)?.addIceCandidate(
                new RTCIceCandidate(data)
            );
        }

        addSocketListener(roomID, ACTIONS.ICE, handleIce);
        return () => {
            removeSocketListener(roomID, ACTIONS.ICE, handleIce);
        }
    }, []);

    useEffect(() => {
        async function handleExit(peerID: number, data: any) {
            console.log('handle exit');
            peerConnections.current.get(peerID)?.close();

            peerConnections.current.delete(peerID);
            peerMediaElements.current.delete(peerID);

            updateClients(list => list.filter(c => c !== peerID));
        }

        addSocketListener(roomID, ACTIONS.EXIT, handleExit);
        return () => {
            removeSocketListener(roomID, ACTIONS.EXIT, handleExit);
        }
    }, []);

    useEffect(() => {
        async function startCapture() {
            localMediaStream.current = await navigator.mediaDevices.getUserMedia({
                audio: true,
                video: {
                    width: 640,
                    height: 480,
                }
            });

            addNewClient(0, () => { // LOCAL_VIDEO
                const localVideoElement = peerMediaElements.current.get(0);

                if (localVideoElement) {
                    localVideoElement.volume = 0;
                    localVideoElement.srcObject = localMediaStream.current;
                }
            });
        }

        startCapture()
            .then(() => sendSocketData(roomID, ACTIONS.JOIN, undefined, null))
            .catch(e => console.error('Error getting userMedia:', e));

        return () => {
            localMediaStream.current?.getTracks().forEach(track => track.stop());
        };
    }, [roomID]);

    const provideMediaRef = useCallback((id, node) => {
        peerMediaElements.current.set(id, node);
    }, []);

    return {
        clients,
        provideMediaRef,
    };
}