import {useState, useRef, useEffect, useCallback} from 'react';

export type CallbackType = <S>(currentState: S) => void;

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};
const useStateWithCallback = <T>(initState: T): [T, (newState: (prev:T) => T, cb?: CallbackType) => void] => {
    const [state, setState] = useState<T>(initState);
    const cbRef = useRef<CallbackType>(noop);

    const updateState = useCallback((newState: (prev:T) => T, cb: CallbackType = noop) => {
        cbRef.current = cb;
        setState(prev => newState(prev));
    }, []);

    useEffect(() => {
        if (cbRef.current) {
            cbRef.current(state);
            cbRef.current = noop;
        }
    }, [state]);

    return [state, updateState];
}

export default useStateWithCallback;