import {WebsocketBuilder} from 'websocket-ts';

type SocketMessage = {
    room: string,
    type: string,
    peer: number,
    data: any,
}
enum SocketStatus {
    INIT = 0,
    OPENED,
    CLOSED
}
export type SocketCallback = (peer: number, data: any) => void;
const rooms = new Map<string, Map<string, SocketCallback[]>>();

let status = SocketStatus.INIT;
const ws = new WebsocketBuilder('wss://chat.tolstyh.com:9502')
    .onOpen((i, ev) => {
        status = SocketStatus.OPENED;
        console.log('socket opened')
    })
    .onClose((i, ev) => {
        status = SocketStatus.CLOSED;
        console.log('socket closed')
    })
    .onError((i, ev) => {
        status = SocketStatus.CLOSED;
        console.log('socket error')
    })
    .onMessage((i, ev) => {
        const message = JSON.parse(ev.data) as SocketMessage;
        rooms.get(message.room)?.get(message.type)?.forEach(listener => {
            listener(message.peer, message.data);
        });
    })
    .onRetry((i, ev) => {
        console.log('socket retry')
    })
    .build();

const addSocketListener = (room: string, type: string, cb: SocketCallback) => {
    if(!rooms.has(room)) rooms.set(room, new Map<string, SocketCallback[]>());
    if(!rooms.get(room)?.has(type)) rooms.get(room)?.set(type, []);
    rooms.get(room)?.get(type)?.push(cb);
}

const removeSocketListener = (room: string, type: string, cb: SocketCallback) => {
    const listeners = rooms.get(room)?.get(type);
    if(listeners) {
        rooms.get(room)?.set(type, listeners.filter(callback => callback !== cb));
    }
}

const sendSocketData = (room: string, type: string, peer: number | undefined, data: any) => {
    const toSend = {
        room,
        type,
        data,
    }
    if(status === SocketStatus.OPENED) {
        console.log(type);
        ws.send(JSON.stringify(toSend));
    }
    else if(status === SocketStatus.INIT) { // waiting for socket open
        const interval = setInterval(() => {
            if(status !== SocketStatus.INIT) {
                clearInterval(interval);
                if(status === SocketStatus.OPENED) {
                    console.log(type);
                    ws.send(JSON.stringify(toSend));
                }
            }
        }, 100);
    }
}

export {addSocketListener, removeSocketListener, sendSocketData};