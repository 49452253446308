import React, {useState} from 'react';
import {useParams} from 'react-router';
import useWebRTC from '../../hooks/useWebRTC';

function layout(clientsNumber = 1) {
    const rowsNumber = Math.ceil(clientsNumber);
    const height = `${100 / rowsNumber}%`;

    return Array.from({length: clientsNumber}).map(i => ({
        width: '640px',
        height: '480px',
    }));
}

function Room() {
    const {id: roomID} = useParams();
    const {clients, provideMediaRef} = useWebRTC(roomID || 'default');
    const videoLayout = layout(clients.length);

    return (
        <div>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexWrap: 'wrap',
                height: '100vh',
            }}>
                {clients.map((clientID, index) => {
                    return (
                        <div key={clientID} style={videoLayout[index]} id={'' + clientID}>
                            <video
                                width='100%'
                                height='100%'
                                ref={instance => {
                                    provideMediaRef(clientID, instance);
                                }}
                                autoPlay
                                playsInline
                                muted={clientID === 0}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    )
}

export default Room;